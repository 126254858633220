@font-face {
  font-family: 'durev-icon';
  src:  url('../fonts/durev-icon.eot?smn2ii');
  src:  url('../fonts/durev-icon.eot?smn2ii#iefix') format('embedded-opentype'),
    url('../fonts/durev-icon.ttf?smn2ii') format('truetype'),
    url('../fonts/durev-icon.woff?smn2ii') format('woff'),
    url('../fonts/durev-icon.svg?smn2ii#durev-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'durev-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-private-status:before {
  content: "\e900";
}
.icon-failed-status:before {
  content: "\e901";
}
.icon-soon-status:before {
  content: "\e902";
}
.icon-pre-sale-status:before {
  content: "\e903";
}
.icon-calc-status:before {
  content: "\e904";
}
.icon-claim:before {
  content: "\e905";
}
.icon-participate:before {
  content: "\e906";
}
.icon-copy:before {
  content: "\e907";
}
.icon-socials-Web:before {
  content: "\e908";
}
.icon-x:before {
  content: "\e909";
}
.icon-telegram:before {
  content: "\e90a";
}
.icon-arrow:before {
  content: "\e90b";
}
.icon-ok:before {
  content: "\e90c";
}
.icon-present:before {
  content: "\e90e";
}
.icon-link:before {
  content: "\e90f";
}
.icon-ended-status:before {
  content: "\e90d";
}
