.pre-card__title {
  display: flex;
  align-items: center;
  gap: 10px;
  &__logo {
    width: 60px;
    height: 60px;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
  &__info {
    h1 {
      font-size: 24px;
      margin: 0;
    }
    p {
      padding: 0;
      margin: 0;
      padding-top: 5px;
    }
  }
}
