.main-header {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    &-img {
      overflow: hidden;
      background-color: var(--c-white);
      width: 50px;
      height: 50px;
    }
    &-text {
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 50px;
      color: #b0c78b;
      background-color: var(--c-dark);
      @media screen and (max-width: 600px) {
        display: none;
        
      }
    }
  }
}
