.one-presale{
    display: flex;
    gap: 40px;
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 850px) {                                                                  
        flex-direction: column-reverse;
        gap: unset;
    }
    &__sidebar{
        flex: 1 1 360px;
        min-width: 320px;
        & > * {
            overflow: hidden;
            margin-bottom: 20px;
        }
    }
    .only-mobile{
        display: none;
        @media screen and (max-width: 850px) {
            display: block;
        }
    }
    .only-desktop{
        display: block;
        @media screen and (max-width: 850px) {
            display: none;
        }
    }
}