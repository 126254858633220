.progress {
  background-color: var(--c-gray-l);
  padding: 10px;
}
.progress-line {
  background-color: var(--c-gray-l);

  &__item {
    padding: 10px;
    border-bottom: 2px solid var(--c-white);
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
  }
  &__item:last-child {
    border-bottom: none;
  }
}
