.first {
  padding: 20px;
  box-sizing: border-box;
  background-size: cover;
  display: flex;
  justify-content: end;
  @media screen and (max-width: 710px) {
    padding: 10px;
  }
  .pre-card {
    max-height: 460px;
  }
}
