:root {
  --br16: 16px;
  --br20: 20px;
  --br30: 30px;
  --br-circle: 50%;
  --br25: 25px;
  --cs-active: #25b857;
  --cs-ended: #159b83;
  --cs-soon: #3d81e7;
  --cs-process: #cdb431;
  --cs-failed: #b16825;
  --c-ocean: #3d81e7;
  --c-white: #ffffff;
  --c-white-trans20: #ffffff33;
  --c-white-trans10: #ffffff1a;
  --c-blue: #004aad;
  --c-gray-l: #ededed;
  --c-gray-d: #737373;
  --c-dark-trans10: #0000001a;
  --c-dark-trans20: #00000033;
  --c-blue-light: #0088cb;
  --c-dark: #000;
  --c-pink: #ff6cb0;
  --c-blue-l: #64cefe;
}
@import-normalize;
@font-face {
  font-family: "Comic Sans MS";
  src: url("../fonts/ComicSansMS.woff2") format("woff2"),
    url("../fonts/ComicSansMS.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

*{
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Comic Sans MS", sans-serif;
  overscroll-behavior: none;
}

.durev-app {
  background-color: var(--c-blue);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}
tc-root * {
  font-family: "Comic Sans MS" !important;
}
.wrap {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px;
}

.br16 {
  border-radius: var(--br16);
}

.br20 {
  border-radius: var(--br20);
}
.br30 {
  border-radius: var(--br30);
}
.br25 {
  border-radius: var(--br25);
}

.br-c {
  border-radius: var(--br-circle);
}

.pre-card {
  max-width: 360px;
  width: 100%;
  padding: 20px;

  min-height: 460px;

  min-width: 250px;
  box-sizing: border-box;
  background-color: var(--c-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pre-sale_failed {
  background-color: var(--cs-failed);
}
.pre-sale_ended {
  background-color: var(--cs-ended);
}

.calc_tokens {
  background-color: var(--cs-process);
}
.active_pre-sale {
  background-color: var(--cs-active);
}
.start-soon {
  background-color: var(--cs-soon);
}
.cap-icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  box-sizing: border-box;
  border: 2px solid var(--c-gray-d);
  border-radius: 1px;
}
.cap-icon.soft {
  transform: rotate(45deg);
}
