.tokenomic-bar{
    width: 100%;
    background-color: var(--c-gray-l);
    padding: 20px;
    text-align: center;
    &__title{
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 20px 0;
    }
}