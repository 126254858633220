.pre-card__button {
  a {
    display: flex;
    color: var(--c-white);
    text-decoration: none;
    gap: 10px;
    p {
      margin: 0;
      display: flex;
      gap: 10px;
      height: 60px;
      align-items: center;
      justify-content: center;
      flex: 1 1 calc(100% - 60px);
      span {
        font-size: 24px;
      }
    }
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      transform: rotate(45deg);
    }
  }
}
