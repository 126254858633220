.referal{
    width: 100%;
    background-color: black;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__head{
        display: flex;
        align-items: center;
        gap: 7px;
    }
    &__dot{
        height: 4px;
        width: 4px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-self: center;
    }
    &__reward{
        font-size: 14px;
        font-weight: 700;
    }
    &__link{
        font-size: 14px;
        font-weight: 400;
    }
    &__button{
        background-color: var(--c-white-trans20);
        width: 100%;
        border-radius: 25px;
        color: white;
        padding: 5px;
        border: none;
        font-size: 20px;
        font-weight: 700;
        font-family: "Comic Sans MS", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        [class^="icon-"]{
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--c-white-trans20);
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
    &__reward{
        display: flex;
        justify-content: space-between;
        padding: 10px 5px;
    }
}

.referal-link{
    display: flex;
    align-items: center;
    gap: 10px;
    &__text{
        display: flex;
        align-items: center;
        border-radius: 25px;
        background-color: var(--c-white-trans10);
        padding: 10px;
        box-sizing: border-box;
        white-space: nowrap;
        gap: 5px;
        overflow: hidden;
        font-size: 12px;
        color: var(--c-gray-d);
        [class^="icon-"]{
            font-size: 20px;
            color: white;
        }
    }
    & > [class^="icon-"]{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);
        font-size: 20px;
        cursor: pointer;
    }
}