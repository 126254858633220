.toasts-container{
    position: fixed;
    top: 60px;
    right: 30px;
    z-index: 1000;
}

.toast{
    background-color: var(--c-white);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 20px;
    button{
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 20px;
    }
}