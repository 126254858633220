.with-durev {
  background-size: cover;
  display: block;
  a {
    text-decoration: none;
    display: inline-flex;
    background-color: var(--c-dark);
    color: var(--c-white);
    padding: 5px 5px 5px 20px;
    align-items: center;
    font-size: 20px;
    gap: 10px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--c-white-trans20);
      width: 40px;
      height: 40px;
    }
  }
  p {
    font-size: 30px;
    font-weight: 700;
  }
}
.with-durev.full {
  background-color: var(--c-blue-l);
  margin-top: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  position: relative;
  @media screen and (max-width: 710px) {
    display: none;
  }
  img {
    height: 100%;
    width: auto;
    position: absolute;
    bottom: -1px;
  }
  img:first-child {
    transform: scale(-1, 1);
    left: 0;
  }
  img:last-child {
    right: 0;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: 0 0 18px 0;
    }
  }
}
