.subscribe-durev {
  border-radius: 35px;
  padding: 10px;
  background-color: var(--c-blue);
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  font-weight: 700;
  color: var(--c-white);
  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: center;
  }
  a {
    text-decoration: none;
    color: var(--c-white);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--c-ocean);
    border-radius: 50%;
  }
}
