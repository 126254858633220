.presale-section {
    background-color: var(--c-white);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    flex: 1 1 calc(100% - 400px);
    gap: 20px;
    @media screen and (max-width: 710px) {
        padding: 10px;
        gap: 10px;
    }
    &__only-mobile{
        display: none !important;
        @media screen and (max-width: 710px) {
            display: flex!important;
        }
    }
    &__only-desktop{
        @media screen and (max-width: 710px) {
            display: none !important;
        }
    }
    &__image {
        width: 100%;
        height: 230px;
        overflow: hidden;
        @media screen and (max-width: 710px) {
            height: 128px;
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    .pre-card__title{
        &__logo{
            width: 80px;
            height: 80px;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 710px) {
            flex-direction: column;
            gap: 10px;
            align-items: start;
        }
        &__social{
            display: flex;
            gap: 10px;
            font-size: 22px;
            .icon-socials-Web{
                font-size: 27px;
            }
            span{
                background-color: var(--c-dark-trans20);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
            }
        }
    }
    &__body{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .progress-line{
            width: 100%;
            @media screen and (max-width: 710px) {
                display: none;
            }
            &__item{
                display: flex;
                padding: 20px 10px;
                &-value{
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    gap: 5px;
                    cursor: pointer;
                }
            }
            span.title{
                font-size: 14px;
            }
            span.value{
                font-size: 14px;
            }
        }
    }

    &__disclaimer{
        @media screen and (max-width: 710px) {
            display: none;
        }
        color: var(--c-gray-d);
        font-size: 12px;
    }
}