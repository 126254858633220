.exchange-durev {
  border-radius: 35px;
  background-color: var(--c-blue);
  padding: 10px;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  font-weight: 700;
  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
  }
  &__rate {
    display: flex;
    gap: 10px;
    color: var(--c-white);
    .logo {
      width: 50px;
      height: 50px;
      overflow: hidden;
      background-color: var(--c-ocean);
    }
    @media screen and (max-width: 375px) {
      align-items: center;
      p{
        margin: 0;
      }
    }
  }
  &__link-buy {
    text-decoration: none;
    color: var(--c-white);
    display: flex;
    align-items: center;
    background-color: var(--c-ocean);
    padding: 5px 5px 5px 20px;
    gap: 10px;
    max-height: 50px;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: var(--c-white-trans20);
    }
  }
  &__value {
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: start;
    }
  }
  &__sign {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
