.wallet-action{
    p{
        margin: 0;
    }
    &__header{
        padding: 10px 0;
        text-align: center;
        color: var(--c-white);
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__status{
        display: flex;
        gap: 10px;
        justify-content: center;
    }
    .countdown{
        font-size: 22px;
        font-weight: 700;
        span{
            font-size: 12px;
            font-weight: 400;
        }
    }
    &__body{
        padding: 10px 20px 20px 20px;
        background-color: var(--c-white);
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__field{
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;
        .input{
            background-color: var(--c-gray-l);
            width: 100%;
            display: flex;
            align-items: center;
            border-radius: 12px;
            padding: 15px;
            input{
                width: 85%;
                background-color: transparent;
                border: none;
                font-size: 16px;
                &:focus{
                    outline: none;
                    border: none;
                }
            }
            input[type=number]::-webkit-outer-spin-button,
            input[type=number]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            span{
                white-space: nowrap;
                color: var(--c-gray-d);
                font-size: 12px;
            }
        }
    }
    &__button{
        border: none;
        border-radius: 30px;
        padding: 10px 20px;
        background-color: var(--c-ocean);
        font-size: 20px;
        font-weight: 700;
        color: var(--c-white);
        display: flex;
        gap: 10px;
        width: 100%;
        justify-content: center;
        align-items: center;
        &:focus{
            outline: none;
            border: none;
        }
        [class^="icon-"]{
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--c-white-trans20);
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
}