.simple-field{
    padding: 15px 10px;
    background-color: var(--c-gray-l);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    &.third{
        flex: 1 1 calc(33.33% - 10px);
    }
    &.half{
        flex: 1 1 calc(50% - 10px);
    }
    &.full{
        flex: 1 1 100%;
    }
    &.quarter{
        flex: 1 1 calc(25% - 10px);
    }
    &.mobile-half{
        @media screen and (max-width: 375px) {
            flex: 1 1 calc(50% - 10px);
        }
    }
    &.mobile-full{
        @media screen and (max-width: 375px) {
            flex: 1 1 100%;
        }
    }
    gap: 5px;
    p{
        margin: 0;
    }
    &__title{
        color: var(--c-gray-d);
        font-size: 12px;
    }
}