.footer-main {
  background-color: var(--c-dark-trans20);
  margin-top: 40px;
  padding: 20px 0;
  width: 100%;
  &__first {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 10px;
    }
  }
  &__second {
    border-top: 1px solid var(--c-white-trans20);
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff69;
  }
}
