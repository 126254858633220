.catalogue {
  width: 100%;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 40px;
  @media screen and (max-width: 710px) {
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
  .first {
    flex: 1 1 calc(((100% / 3) * 2) - 40px);
    width: 100%;
    max-width: 760px;
  }
}
